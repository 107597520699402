<template>
  <div id="home">
    <b-carousel v-if="false">
      <b-carousel-item v-for="(carousel, i) in carousels" :key="i">
        <section :class="`hero is-medium is-${carousel.color}`">
          <div class="hero-body has-text-centered">
            <h1 class="title">{{carousel.text}}</h1>
          </div>
        </section>
      </b-carousel-item>
    </b-carousel>
    <div class="banner">
      <div class="banner-cont">
        <div class="cont-info">
          <span class="text family-bold bold size-45">¿COMIDA?</span>
          <div style="width: 120px;border: 1.5px solid #CDD500; margin: 10px 0"></div>
          <span class="text size-36 center" style="line-height: normal;">Disfruta de tus restaurantes favoritos y de su servicio de domicilio</span>
        </div>
      </div>
    </div>
    <div class="container-section">
      <p class="text left big bold margin-bot" v-if="false">Promociones</p>
      <b-carousel-list :data="items" :repeat="true" :config="seatting" :has-grayscale="true" v-if="false"/>
      <p class="text left big bold margin-bot" v-if="restaurans">Restaurantes</p>
      <div class="cards-container" v-if="restaurans">
        <div :style="{'animation':'moveInLeft '+index/3+'s ease-in-out' }" :class="{'disabled': res.isOpen == 0 || (res.isPrograming == 0 && res.closed == 1), 'disabled-cursor': res.isPrograming == 1 && res.closed == 1 }" class="card" v-for="(res,index) in restaurans" :key="index" @click="goVendor(res)">
          <div class="card-content">
            <div v-if="res.logo !== undefined" class="logo-cont" :style="{'background-image': 'url(' + res.logo + ')'}"></div>
            <div v-else class="logo-cont"><i id="icon-logo" class="fas fa-utensils"></i></div>
            <div v-if="res.banner" class="flex-1 shadow-bot" :style="{'background-image': 'url(' + res.banner + ')','background-size':'cover'}">
            </div>
            <div v-else class="flex-1 shadow-bot">
            </div>
            <div class="flex-1 name-card">
              <p class="text normal center margin-bot-name">{{res.displayName}}</p>
            </div>
          </div>
          <span v-if="res.isOpen == 0 && res.closed == 0" class="text bold size-20 not-allowed">No disponible</span>
          <span v-if="res.isPrograming == 1 && res.closed == 1" class="text bold size-20 not-allowed">Cerrado <br>{{ res.open }}</span>
          <span v-if="res.isPrograming == 0 && res.closed == 1" class="text bold size-20 not-allowed">Cerrado <br>{{ res.open }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LocalStorage from 'store';
import _ from 'lodash';
import { mapGetters } from 'vuex';
import deliveryAddress from '@/components/templateFood/modals/deliveryAddress';
export default {
  props: ['findVendor', 'url'],
  metaInfo: {
    title: 'Domicilios Precompro.com',
    link: [
      { vmid: 'icon', rel: 'icon', href: '/favicon.ico' }
    ]
  },
  name: 'Home',
  data () {
    return {
      load: false,
      carousels: [
        { text: 'Slide 1', color: 'primary' },
        { text: 'Slide 2', color: 'info' },
        { text: 'Slide 3', color: 'success' },
        { text: 'Slide 4', color: 'warning' },
        { text: 'Slide 5', color: 'danger' }
      ],
      seatting: {
        hasGrayscale: true,
        itemsToShow: 1,
        breakpoints: {
          568: {
            hasGrayscale: false,
            itemsToShow: 2
          },
          768: {
            hasGrayscale: false,
            itemsToShow: 3
          },
          960: {
            hasGrayscale: true,
            itemsToShow: 4
          },
          1400: {
            hasGrayscale: true,
            itemsToShow: 5
          }
        }
      },
      items: [
        { title: 'Slide 1', image: 'https://picsum.photos/id/0/1230/500' }
      ],
      restaurans: null
    };
  },
  mounted () {
    LocalStorage.remove('tienda_Coverage_client');
    LocalStorage.remove('address_Coverage_client');
    LocalStorage.remove('center_Coverage_client');
    this.$store.commit('setVendor', []);
    this.$store.commit('setGlobalLoader', true);
    const thes = this;
    setTimeout(function () {
      if (thes.url !== 'all') {
        thes.getVendorUrl(thes.url);
      } else {
        thes.openCoverage();
      }
    }, 0);
  },
  computed: {
    ...mapGetters([
      'getCart',
      'getVendor',
      'globalLoader',
      'loader'
    ])
  },
  methods: {
    isAvail (times) {
      const dayOfWeek = parseInt(this.$moment().format('d'));
      const timeCurrent = this.$moment().format('HH:mm');
      if (times.dayTimes !== undefined) {
        const data = _.find(times.dayTimes, { day: dayOfWeek });
        if (data) {
          if (data.hasAttention === 1) {
            if (timeCurrent >= data.open && timeCurrent <= data.close) {
              times.closed = 0;
            } else {
              times.closed = 1;
              times.open = 'Abre a las ' + this.$moment(new Date('1970-01-01 ' + data.open)).format('hh:mm A');
              times.isPrograming = 1;
            }
          } else {
            times.closed = 1;
            times.open = 'Hoy no abre';
            times.isPrograming = 0;
          }
        }
      } else {
        times.closed = 1;
        times.open = 'Hoy no abre';
        times.isPrograming = 0;
      }
      return times;
    },
    getMerchants () {
      const instance = this;
      this.$http.get('/marketplace/client/vendors').then(({ data }) => {
        Object.values(data.data).map(info => {
          instance.isAvail(info);
        });
        this.restaurans = data.data;
        this.$store.commit('setGlobalLoader', false);
        this.load = true;
      }).catch(err => {
        this.$store.commit('setGlobalLoader', false);
        this.$buefy.toast.open({
          duration: 3000,
          message: this.$parent.listErrors(err.request.response, err.request.status),
          position: 'is-top',
          type: 'is-danger'
        });
      });
    },
    goVendor (vendor) {
      if (vendor.isOpen === 0 || (vendor.closed === 1 && vendor.isPrograming === 0)) {
        return false;
      }
      if ((vendor.closed === 1 && vendor.isPrograming === 1) || vendor.isOpen === 1) {
        this.$router.replace('/' + vendor._id + '/restaurant');
      }
    },
    getCartDeleteAll () {
      this.$http.delete('marketplace/client/cart/delete/all/' + LocalStorage.get('domicompro-uid-user')).then(({ data }) => {
        this.$store.commit('setCart', data.data);
      }).catch(err => {
        this.$buefy.toast.open({
          duration: 3000,
          message: this.$parent.listErrors(err.request.response, err.request.status),
          position: 'is-top',
          type: 'is-danger'
        });
      });
    },
    searchVendorsQuery (string) {
      clearTimeout(this.timeout);
      const instance = this;
      this.timeout = setTimeout(function () {
        if (string !== '' && string.length >= 3) {
          instance.$store.commit('setGlobalLoader', true);
          const data = {};
          data.search = string;
          instance.$http.post('marketplace/client/search/vendor', data).then(({ data }) => {
            if (data.data != null) {
              instance.restaurans = data.data;
            } else {
              instance.restaurans = [];
            }
            instance.$store.commit('setGlobalLoader', false);
          }).catch(err => {
            instance.$store.commit('setGlobalLoader', false);
            instance.$buefy.toast.open({
              duration: 3000,
              message: this.$parent.listErrors(err.request.response, err.request.status),
              position: 'is-top',
              type: 'is-danger'
            });
          });
        } else if (string === '') {
          instance.$store.commit('setGlobalLoader', true);
          instance.getMerchants();
        }
      }, 500);
    },
    getVendorUrl (url) {
      this.$store.commit('setGlobalLoader', true);
      this.$http.post('/marketplace/client/vendor/url', { url: url }).then(({ data }) => {
        this.$router.replace('/' + data.data._id + '/restaurant');
        this.$store.commit('setGlobalLoader', false);
      }).catch(err => {
        this.$store.commit('setGlobalLoader', false);
        this.$buefy.toast.open({
          duration: 3000,
          message: this.$parent.listErrors(err.request.response, err.request.status),
          position: 'is-top',
          type: 'is-danger'
        });
      });
    },
    openCoverage () {
      this.$store.commit('setGlobalLoader', false);
      const thes = this;
      thes.$buefy.modal.open({
        parent: thes,
        component: deliveryAddress,
        hasModalCard: true,
        trapFocus: true,
        canCancel: false,
        customClass: 'modalCoverage',
        events: {
          setDataCoverage (event) {
            thes.restaurans = event;
          }
        }
      });
    }
  },
  watch: {
    findVendor () {
      this.searchVendorsQuery(this.findVendor);
    },
    getCart () {
      if (Object.keys(this.getCart).length > 0) {
        this.getCartDeleteAll();
      }
    }
  }
};
</script>
<style lang="scss">
#home{
  .text{
    &.margin-bot{
      margin-bottom: 30px;
    }
    &.margin-top{
      margin-top: 30px;
    }
    &.margin-bot-name{
    margin-bottom: 20px;
    padding: 0 15px;
    }
  }
  #icon-logo{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 30px;
    width: 30px;
  }
  .banner{
    display: flex;
    justify-content: center;
    border-bottom: 3px solid rgb(205, 213, 0);
    .banner-cont{
      display: flex;
      justify-content: flex-end;
      padding: 0 5% 5% 0;
      width: 100%;
      min-width: 400px;
      height: 430px;
      background-size: cover;
      background-image: url('../../../assets/image/bannerHome2.jpg');
      background-position: left center ;
      .cont-info{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 400px;
      }
    }
  }
  @media (max-width: 700px) {
    .banner{
      .banner-cont{
        padding: inherit;
        justify-content: center;
        .cont-info{
          width: 100%;
          padding: 0 10px;
          background: rgba(255, 255, 255, 0.75);
        }
      }
    }
  }
}
</style>
