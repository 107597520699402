<template>
  <div>
    <CustomNavBar @find="search = $event"></CustomNavBar>
    <HomePage :findVendor="search" :url="url"></HomePage>
    <Footer></Footer>
  </div>
</template>
<script>
import HomePage from '@/components/templateBasic/homePage';
import CustomNavBar from '@/components/templateBasic/navBar';
import Footer from '@/components/templateBasic/Footer';
import { mapGetters } from 'vuex';
import _ from 'lodash';
import LocalStorage from 'store';
export default {
  components: { HomePage, CustomNavBar, Footer },
  props: ['findVendor'],
  data () {
    return {
      url: null,
      search: null,
      config: {
        toolbarColor: '#ffffff',
        footerColor: '#151E26',
        footerTextColor: '#ffffff',
        buttonColor: '#559A70',
        buttonTextColor: '#ffffff'
      }
    };
  },
  computed: {
    ...mapGetters([
      'getCart',
      'getVendor'
    ])
  },
  mounted () {
    this.$store.commit('setGlobalLoader', true);
    this.url = this.$parent.urlVendor();
  },
  methods: {
    isAvail (times) {
      const dayOfWeek = parseInt(this.$moment().format('d'));
      const timeCurrent = this.$moment().format('HH:mm');
      if (times.dayTimes !== undefined) {
        const data = _.find(times.dayTimes, { day: dayOfWeek });
        if (data) {
          if (data.hasAttention === 1) {
            if (timeCurrent >= data.open && timeCurrent <= data.close) {
              times.closed = 0;
            } else {
              times.closed = 1;
              times.open = 'Abre a las ' + this.$moment(new Date('1970-01-01 ' + data.open)).format('hh:mm A');
              times.isPrograming = 1;
            }
          } else {
            times.closed = 1;
            times.open = 'Hoy no abre';
            times.isPrograming = 0;
          }
        }
      } else {
        times.closed = 1;
        times.open = 'Hoy no abre';
        times.isPrograming = 0;
      }
      return times;
    },
    getMerchants () {
      const instance = this;
      this.$parent.isLoadingGlobal = true;
      this.$http.get('/marketplace/client/vendors').then(({ data }) => {
        Object.values(data.data).map(info => {
          instance.isAvail(info);
        });
        this.restaurans = data.data;
        this.$parent.isLoadingGlobal = false;
        this.load = true;
      }).catch(err => {
        this.$parent.isLoadingGlobal = false;
        this.$buefy.toast.open({
          duration: 3000,
          message: this.$parent.listErrors(err.request.response, err.request.status),
          position: 'is-top',
          type: 'is-danger'
        });
      });
    },
    goVendor (vendor) {
      if (vendor.isOpen === 0 || (vendor.closed === 1 && vendor.isPrograming === 0)) {
        return false;
      }
      if ((vendor.closed === 1 && vendor.isPrograming === 1) || vendor.isOpen === 1) {
        this.$router.replace('/' + vendor._id + '/restaurant');
      }
    },
    getCartDeleteAll () {
      this.$http.delete('marketplace/client/cart/delete/all/' + LocalStorage.get('domicompro-uid-user')).then(({ data }) => {
        this.$store.commit('setCart', data.data);
      }).catch(err => {
        this.$buefy.toast.open({
          duration: 3000,
          message: this.$parent.listErrors(err.request.response, err.request.status),
          position: 'is-top',
          type: 'is-danger'
        });
      });
    },
    searchVendorsQuery (string) {
      clearTimeout(this.timeout);
      const instance = this;
      this.timeout = setTimeout(function () {
        if (string !== '' && string.length >= 3) {
          instance.$parent.isLoadingGlobal = true;
          const data = {};
          data.search = string;
          instance.$http.post('marketplace/client/search/vendor', data).then(({ data }) => {
            if (data.data != null) {
              instance.restaurans = data.data;
            } else {
              instance.restaurans = [];
            }
            instance.$parent.isLoadingGlobal = false;
          }).catch(err => {
            instance.$parent.isLoadingGlobal = false;
            instance.$buefy.toast.open({
              duration: 3000,
              message: this.$parent.listErrors(err.request.response, err.request.status),
              position: 'is-top',
              type: 'is-danger'
            });
          });
        } else if (string === '') {
          instance.$parent.isLoadingGlobal = true;
          instance.getMerchants();
        }
      }, 500);
    },
    getVendorUrl (url) {
      this.$parent.isLoadingGlobal = true;
      this.$parent.isBlockedGlobal = true;
      this.$http.post('/marketplace/client/vendor/url', { url: url }).then(({ data }) => {
        this.goVendor(data.data);
        this.$parent.isLoadingGlobal = false;
      }).catch(err => {
        this.$parent.isLoadingGlobal = false;
        this.$buefy.toast.open({
          duration: 3000,
          message: this.$parent.listErrors(err.request.response, err.request.status),
          position: 'is-top',
          type: 'is-danger'
        });
      });
    }
  }
};
</script>
